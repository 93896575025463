import React from "react"; 
import Button from "../../components/Button/Button";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import './ContactUs.css';

const ContactUs = () => {
    return (
        <div className="contact_us">
            <div className="address">
                <SectionTitle title='Contact Us' fontSize={34} />
                <div className="address_part">
                    <h2 className="address_title">Address</h2>
                </div>
                <div className="office_address">
                    <p>Aashirvad Complex,Near Jaipur Golden
                    <br/>Aamagarh Batti, Transport Nagar,Jaipur
                    <br />Mobile: +91-9773383042
                    </p>
                </div>
                <div className="contact_button">
                    <Button name="Apply Now" width={150} height={40} link='https://play.google.com/store/apps/details?id=com.lcgm.ascend_loan'/>
                </div>
            </div>
            <div className="directions">
                <SectionTitle title='Locate Us' fontSize={34} />
                <div className="map_frame">
                <iframe
                    title="EvCred"
                    width="100%"
                    height="330"
                    frameBorder="0"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d222.36355382123156!2d75.84597896034597!3d26.909306741986878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDU0JzMzLjUiTiA3NcKwNTAnNDUuOSJF!5e0!3m2!1sen!2sin!4v1681909355277!5m2!1sen!2sin"
                />
                </div>
                <div className="direction_button">
                    <a href="https://www.google.com/maps/dir//26.9092926,75.8460727/@26.9093067,75.845979,21z?hl=en" className="cursor" target="_blank" rel="noopener noreferrer">
                        <Button name='GET DIRECTIONS' link={`https://www.google.com/maps/dir//26.9092926,75.8460727/@26.9093067,75.845979,21z?hl=en`} width={200} height={40} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;