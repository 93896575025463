import React from 'react';
import {images} from '../../constants/images'
import './SubHeading.css'

const SubHeading = ({title}) => (
  <div className='app__subheading'>
    <p className='p__heading-jost'>{title}</p>
  </div>
);

export default SubHeading;
