import React, {useEffect, useState} from "react";
import './VehicleLists.css'
import { API_URL } from "../../constants/apis";
import CarList from '../../components/CarList/CarList';
import { isEmpty, get } from "lodash";
import axios from "axios";
import Grid from '@mui/material/Grid';

const VehicleLists = () => {

    const [vehicles, setVehicles] = useState([])
    
    useEffect(() => {
        const asyncFn = async () => {
            const res = await axios.get(`${API_URL}/used_vehicle`);
            if(!isEmpty(get(res, 'data', []))){
                const vehicleData = get(res, 'data.data', [])
                setVehicles(vehicleData)
            }
        }
        asyncFn()
    }, [])

    return (
        <div className="vehicle_lists">
            {(function(row, i, len){
                while(i < len){
                    let data = vehicles[i]
                    row.push(<CarList key={i} vehicleDetails = {data}  folder={i+1} />)
                    i++
                }
                return row
            })([], 0, vehicles.length)}
        </div>
    );
}

export default VehicleLists;