import React from "react";
import SectionCard from "../../components/SectionCard/SectionCard";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import images from "../../constants/images";

import './AboutUs.css'

const AboutUs = () => {
    return (
        <section className="app_about_us">
            
        </section>
    )
}

export default AboutUs;