import React from 'react';
import images from '../../constants/images';
import {AiFillTwitterCircle} from 'react-icons/ai'
import {TiSocialYoutubeCircular, TiSocialTwitterCircular, TiSocialFacebookCircular} from 'react-icons/ti'
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';

import './Footer.css';

const Footer = (props) => (
  <div className='app__footer section_margin'>
    <div className='footer_row_form'>
      <div><img src={images.evcredLogo} /></div>
      {/* <div className='footer_apply_now'>
        <div className='form_inputs'>
          <input placeholder='Mobile' className='form_input' />
          <input placeholder='Email' className='form_input' />
        </div>
        <Button name="Apply For Partner" width={180} height={40} backgroundColor='#003F5A' fontColor="#ffffff" />
      </div> */}
    </div>
    <div className='footer_links'>
      <div className='footer_link_row'>
        {/* <div className='footer_link_text'>About Us |Contact Us |Terms & Conditions | Privacy & Policy</div> */}
        <div className='footer_link_text'>Call us- +91-9773383042</div>
      </div>
      <div className='footer_social'>
        <TiSocialFacebookCircular className='footer_social_icon' />
        <TiSocialTwitterCircular className='footer_social_icon'/>
        <TiSocialYoutubeCircular className='footer_social_icon'/>
      </div>
    </div>
  </div>
);

export default Footer;
