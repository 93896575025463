import React, {useContext, useEffect, useState} from 'react';
import images from "../../constants/images";
import './SingleVehicle.css'
// import ButtonWithText from "../../components/ButtonWIthText/ButtonWithText";
import {BiRupee} from 'react-icons/bi';
import {BsSpeedometer, BsTruckFlatbed, BsFillLightningFill} from 'react-icons/bs';
import {RiTimerFlashLine, RiTimeLine} from 'react-icons/ri';
import {FaCarBattery} from 'react-icons/fa';
import ApplyLoan from "../../components/ApplyLOan/ApplyLoan";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import axios from 'axios';
import { get, isEmpty} from 'lodash';
import { API_URL } from "../../constants/apis";
import { useParams } from 'react-router-dom';
import Alert  from '@mui/material/Alert';
import moment from 'moment';

// class SingleVehicle extends React.Component {
const SingleVehicle = () => {

    const params = useParams()
    const [vehicleData, setVehicleDetails] = useState([])
    const [isShowInterest, setIsShowInterest] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [isApplyLoan, setIsApplyLoan] = useState(false)
    const [name , setName] = useState('')
    const [amount, setAmount] = useState('')
    const [mobile, setMobile] = useState('')
    const [mobileError, setMobileError] = useState('')
    const [nameError, setNameError] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [otp, setOtp] = useState('')
    const [success, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [apiError, setError] = useState(false)
    const [isImageSelected, setIsImageSelected] = useState(false)
    const [selectedImg, setSelectedImg] = useState('')
    const [slideIndex, setSlideIndex] = useState(0)
    const [images, setImages] = useState([])
    
    useEffect(() => {
        vehicleDetails()
        window.scrollTo(0,0)
    }, [])

    const vehicleDetails = async() => {
        const vehicleId = params.vehicle_id
        const res = await axios.get(`${API_URL}/used_vehicle/${vehicleId}`);
        if(!isEmpty(get(res, 'data', []))){
            const vehicleData = get(res, 'data.data', [])
            setAmount(vehicleData.estimated_price)
            setVehicleDetails(vehicleData)
            setImages(get(vehicleData, 'all_image', []))
        }
    }

    const handleModal = () => {
        setIsShowInterest(false)
        resetData()
    }

    const handleImageModal = () => {
        setIsImageSelected(false)
    }

    const selectImageZoom = (e, img, index) => {
        setIsImageSelected(true)
        setSelectedImg(img)
    }

    const moveSlide = (prev, Index) => {
        const length = images.length
        
        if(prev){
            setSlideIndex(slideIndex + 1)
            if(Index >= length-1){
                setSlideIndex(0)
            }
        } else {
            setSlideIndex(slideIndex - 1)
            if(Index < 1){
                setSlideIndex(length)
            }
        }        
    }

    const onInputChange = (e, setValue) => {
        let value = e.target.value
            setValue(value) 
    }

    const resetData = () => {
        setName('')
        setMobile('')
        setMobileError('')
        setNameError('')
        setIsOtpSent(false)
    }

    const sendOtp = async() => {
        if(validate()){
            const body = {
                name : name,
                mobile : mobile,
                amount : amount,
                applied_for_loan : isApplyLoan,
                used_vehicle_id : params.vehicle_id
            }
            if(!isOtpSent){   
                try{
                    const res = await axios.post(`${API_URL}/send_otp`, body);
                    if(!isEmpty(get(res, 'data.data', {})) && res.data.status === 'success'){
                        setIsOtpSent(true)
                        setSuccess(true)
                        setError(false)
                        setSuccessMessage('OTP Sent Successfully On Your Number')
                        setTimeout(() => {
                            setSuccess(false)
                        }, 2000)
                    }
                } catch (error){
                   if(error){
                       setSuccess(true)
                       setError(true)
                       setSuccessMessage('You are already verified')
                       setTimeout(() => {
                        setSuccess(false)
                    }, 2000)
                   }
                }    
                
            } else {
                body.otp = otp
                const res = await axios.post(`${API_URL}/verify_otp`, body);
                if(get(res, 'data.success', false)){
                    setSuccess(true)
                    setSuccessMessage('OTP Verified Successfully')
                    setIsShowInterest(false)
                    setIsOtpSent(false)
                    setTimeout(() => {
                        setSuccess(false)
                    }, 2000)
                }
            }
        }
    }

    const showInterest = () => {
        setIsShowInterest(true)
        setIsApplyLoan(false)
    }

    const applyLoan = () => {
        setIsApplyLoan(true)
        setIsShowInterest(true)
    }

    const validate = () => {
        let validateFlag = true
        if(isEmpty(mobile)){
            setMobileError('Mobile No is required')
            validateFlag = false
        } else {
            setMobileError('')
        }
        if(isEmpty(name)){
            setNameError('Name is required') 
            validateFlag = false
        } else {
            setNameError('') 
        } 
        return validateFlag
    }

    return (
        <div className="app_single_vehicle">
            <div className="image_container">
                <div className="vehicle_images">
                    <div className="vehicle_img_slider">
                        {!isEmpty(vehicleData) && !isEmpty(vehicleData.all_image) && vehicleData.all_image.map((img, index) => {
                            if(index < 3){
                                return <img onClick={e => selectImageZoom(e, `/used_images/${params.vehicle_id}/${img}`, index)} width='100px' className="image_main_vehicle_single" src={`/used_images/${params.vehicle_id}/${img}`} style={{cursor:'pointer'}}></img>
                            }
                        })}
                        {/* <img onClick={e => selectImageZoom(e, images.erick1a)} width='100px' className="image_main_vehicle_single" src={images.erick1a} style={{cursor:'pointer'}}></img>
                        <img onClick={e => selectImageZoom(e, images.erick1b)} width='100px' className="image_main_vehicle_single" src={images.erick1b} style={{cursor:'pointer'}}></img>
                        <img onClick={e => selectImageZoom(e, images.erick1c)} width='100px' className="image_main_vehicle_single" src={images.erick1c} style={{cursor:'pointer'}}></img> */}
                    </div>
                    <div id="myModalImage" className="modal_image" style={{display : `${isImageSelected  ? 'block' : 'none'}`}}>
                        <button className='slide_prev' onClick={() => moveSlide(false, slideIndex)}>&#10094;</button>
                        <button className='slide_next' onClick={() => moveSlide(true, slideIndex)}>&#10095;</button>
                        <div className="modal-image-content">
                            <div onClick={handleImageModal} className="close_image">&times;</div>
                            <div className='vehicle_mage_zoom'>
                                {images.map((item, i) => {
                                    return <img key={i} className='vehicle_zoomed_img' style={{display : `${i === slideIndex ? 'block' : 'none'}`}} src={`/used_images/${params.vehicle_id}/${item}`} />
                                })}
                                {/* <img className='vehicle_zoomed_img' style={{display : 'block'}} src={selectedImg} />
                                <img className='vehicle_zoomed_img' style={{display : 'none'}} src={selectedImg} />
                                <img className='vehicle_zoomed_img' style={{display : 'none'}} src={selectedImg} />
                                <img className='vehicle_zoomed_img' style={{display : 'none'}} src={selectedImg} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="vehicel_main_img">
                        <img width='100px' className="image_main_vehicle_single" src={`/used_images/${params.vehicle_id}/${vehicleData.main_image}`}></img>
                    </div>
                </div>
                <div className="vehicle_discription">
                    <div className="vehicle_name">
                        <div>
                            <h2 className="vehicle_name">{vehicleData.brand} {vehicleData.model}</h2>
                        </div>
                        <div>
                            <div className="basic_info" style={{marginTop : '10px'}}>
                                <p className="p_basic_info">{vehicleData.odometer_reading} Km</p>
                                <p className="p_basic_info" style={{marginLeft : '20px'}}>{vehicleData.battery_chemistry}</p>
                            </div>
                            <div className="vehicle_location" style={{marginTop : '10px'}}>
                                <p className="p_basic_info">Anchor Mall, Civil Lines Metro Station, Jaipur</p>
                            </div>
                        </div>
                        <div className="vehicle_buy_action" style={{marginTop : '30px'}}>
                            <div className="interested">
                                <div className="vehicle_price_section"><BiRupee /><p>{vehicleData.estimated_price}/-</p></div>
                                <div className='app__button_with_text' style={{marginTop : '10px'}}>
                                    <Link onClick={showInterest} style={{width:200, backgroundColor : "#27AAE2", color:"#fff"}} className='app__button_hover__with_text'>
                                        <p className='button_text'>Show Interest</p>
                                        <p className='button_sub_text'>Limited Stock</p>
                                    </Link>
                                </div>
                                {/* <ButtonWithText name="Interested" subText ="Limited Stock" width='200' backgroundColor = "#27AAE2" fontColor="#fff" fontSize = {24} /> */}
                            </div>
                            <div className="get_loan" style={{marginLeft : '20px'}}>
                                <div className="vehicle_price_section"><p>Emi : 6000/month</p></div>
                                <div className='app__button_with_text' style={{marginTop : '10px'}}>
                                    <Link onClick={applyLoan} style={{width:200, backgroundColor : "#5A960C", color:"#fff"}} className='app__button_hover__with_text'>
                                        <p className='button_text'>Get Loan</p>
                                        <p className='button_sub_text'>In 30 mins</p>
                                    </Link>
                                </div>
                                {/* <ButtonWithText name="Get Loan" subText = 'In 30 mins' width='200' backgroundColor = "#5A960C" fontColor="#fff" fontSize = {24} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="vehicle_short_description"></div>
                    <div className="vehicle_buy_section"></div>
                </div>
            </div>
            <div className="key_specifications">
                <div className="vehicle_main_specs">
                    <p className="description_section_heading">Key Specifications</p>
                </div>
                <div className="specs_cards">
                    <div className="spec_card">
                        <BsSpeedometer style={{width:'40px', height:'40px'}}/>
                        <p style={{fontSize : '14px'}}>Km Driven</p>
                        <p>{vehicleData.odometer_reading}</p>
                    </div>
                    <div className="spec_card">
                        <RiTimeLine style={{width:'40px', height:'40px'}} />
                        <p style={{fontSize : '14px'}}>Vehicle Age</p>
                        <p>{vehicleData.vehicle_age} Months</p>
                    </div>
                    <div className="spec_card">
                        <FaCarBattery style={{width:'40px', height:'40px'}} />
                        <p style={{fontSize : '14px'}}>Battery Warranty</p>
                        <p>{vehicleData.warranty} Months</p>
                    </div>
                    <div className="spec_card">
                        <RiTimerFlashLine style={{width:'40px', height:'40px'}} />
                        <p style={{fontSize : '14px'}}>Current Mileage</p>
                        <p>{vehicleData.battery_mileage}/Cycle</p>
                    </div>
                    <div className="spec_card">
                        <BsTruckFlatbed style={{width:'40px', height:'40px'}} />
                        <p style={{fontSize : '14px'}}>Chassis Rating</p>
                        <p>{vehicleData.chassis_condition_grading}/10</p>
                    </div>
                    <div className="spec_card">
                        <BsFillLightningFill style={{width:'40px', height:'40px'}} />
                        <p style={{fontSize : '14px'}}>Fast Charging</p>
                        <p>NO</p>
                    </div>
                </div>
            </div>
            <div className="key_specifications_tables">
                <div className="vehicle_main_specs_table">
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Overview</th>
                            </tr>
                            <tr>
                                <td>Make</td>
                                <td>{vehicleData.brand}</td>
                            </tr>
                            <tr>
                                <td>Model</td>
                                <td>{vehicleData.model}</td>
                            </tr>
                            <tr>
                                <td>Year</td>
                                <td>{moment(vehicleData.invoice_date).format('YYYY')}</td>
                            </tr>
                            <tr>
                                <td>Color</td>
                                <td>{vehicleData.colour}</td>
                            </tr>
                            <tr>
                                <td>Onroad Price (new)</td>
                                <td>{vehicleData.ex_showroom_price}</td>
                            </tr>
                            <tr>
                                <td>Emi Start</td>
                                <td>6000/month</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="vehicle_main_specs_table">
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Battery Specification</th>
                            </tr>
                            <tr>
                                <td>Battery Capacity</td>
                                <td>{vehicleData.battery_capacity} KWh</td>
                            </tr>
                            <tr>
                                <td>Battery Chemistry</td>
                                <td>{vehicleData.battery_chemistry}</td>
                            </tr>
                            <tr>
                                <td>Battery Company</td>
                                <td>{vehicleData.battery_make}</td>
                            </tr>
                            <tr>
                                <td>Battery Mileage</td>
                                <td>{vehicleData.battery_mileage} KM/Cycle</td>
                            </tr>
                            <tr>
                                <td>Fast Charging</td>
                                <td>No</td>
                            </tr>
                            <tr>
                                <td>Charging Time</td>
                                <td>{vehicleData.charging_time} hr</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>  
            <div className="key_specifications_tables">
                <div className="vehicle_main_specs_table">
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Inspection Report</th>
                            </tr>
                            <tr>
                                <td>Chassis Rating</td>
                                <td>{vehicleData.chassis_condition_grading}/10</td>
                            </tr>
                            <tr>
                                <td>Battery Warranty</td>
                                <td>{vehicleData.warranty} Months</td>
                            </tr>
                            <tr>
                                <td>Wiring Status</td>
                                <td>Good</td>
                            </tr>
                            <tr>
                                <td>Tyre Condition</td>
                                <td>{vehicleData.tyre_condition_percentage}</td>
                            </tr>
                            <tr>
                                <td>Seats & Cushioning Condition</td>
                                <td>{vehicleData.seats_cushioning_condition_grading}/10</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="vehicle_main_specs_table">
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan={2}>Other Specification</th>
                            </tr>
                            <tr>
                                <td>Motor Power</td>
                                <td>{vehicleData.motor_power} Watt</td>
                            </tr>
                            <tr>
                                <td>Motor Type</td>
                                <td>{vehicleData.motor_type}</td>
                            </tr>
                            <tr>
                                <td>Seating Capacity</td>
                                <td>{vehicleData.sitting_capacity}</td>
                            </tr>
                            <tr>
                                <td>Roof Fiber</td>
                                <td>Heavy Fiber</td>
                            </tr>
                            <tr>
                                <td>Ownership Status</td>
                                <td>{vehicleData.ownership_status}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>  
            {success && <div className='response_alert'>
                <Alert style={{background: `${!apiError ? '#5A960C' : 'red'}`, color:'#fff', fontSize:18}} severity= {!apiError ? "success" : 'error'}>{successMessage}</Alert>
            </div>}
            <div id="myModal" className="modal" style={{display : `${isShowInterest  ? 'block' : 'none'}`}}>
                <div className="modal-content">
                    <div onClick={handleModal} className="close">&times;</div>
                        <p className='apply_now_text'>Verify yourself to {isApplyLoan ? 'apply for loan' : 'show interest'}</p>
                            <form className="form_apply_loan">
                            <div className='form_inputs_loan'>
                                <p className='input_label'>Your Name</p>
                                <input 
                                    placeholder='Enter Name' 
                                    className='form_input apply_loan'
                                    value={name}
                                    onChange={e => onInputChange(e, setName)} 
                                />
                                {!isEmpty(nameError) && <p className="error_messages">{nameError}</p>}
                                <p className='input_label'>Your Mobile</p>
                                <input 
                                    placeholder='Enter Mobile' 
                                    className='form_input apply_loan'
                                    value={mobile}
                                    onChange={e => onInputChange(e, setMobile)} 
                                    maxLength={10}
                                />
                                {!isEmpty(mobileError) && <p className="error_messages">{mobileError}</p>}
                                {isApplyLoan && 
                                <div className='input_lavel_group'>
                                    <p className='input_label'>Vehicle Price</p>
                                    <input 
                                        placeholder='Amount' 
                                        className='form_input apply_loan'
                                        value = {amount}
                                        disabled={true}
                                    />
                                </div>}
                                {isOtpSent && 
                                    <input 
                                        placeholder='Enter OTP' 
                                        value={otp}
                                        onChange={e => onInputChange(e, setOtp)} 
                                        maxLength={6}
                                        className='form_input apply_loan'
                                />}
                                </div>
                                <div className='app__button'>
                                <button
                                    onClick={() => sendOtp()}
                                    className='app__button_interested'
                                    type='button'
                                    style={{width : 150, height : `${40}px` , backgroundColor : isSubmitted ? 'grey' : '#27AAE2', color : '#fff', borderRadius : 10, borderColor : 'none', border : 0, cursor:'pointer', fontSize : 20, marginTop : '15px'}}
                                    disabled = {isSubmitted}
                                >
                                {`${!isOtpSent ? 'Send OTP' : isOtpSent && isApplyLoan ? 'Apply Now' : 'Verify Now'}`}
                                </button>
                            </div>
                        </form>
                </div>
            </div>
    </div>
    )
    
}

export default SingleVehicle;