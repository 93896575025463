import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import ascendLogo from '../assets/ascend_capital.png'
import mainHeaderImg from '../assets/ascend_capital_erickshaw.png'
import footerBg from '../assets/footer_bg.svg'
import whyChooseUsBg from '../assets/why_choose_us.svg'
import chooseUs from '../assets/choose_us.png'
import SectionBg2 from '../assets/section_bg_2.svg'
import playStore from '../assets/ascend_playstore.png'
import storeRatting from '../assets/ratting.png'
import masFinance from '../assets/investors/mas_logo.png'
import mortgageLoan from '../assets/mortgage_loan_ascend_capital.jpg'
import eRickShaw from '../assets/ascend_capital_erickshaw_original.jpg'
import eBike from '../assets/ascend_capital_ebike.jpg'
import l5Loan from '../assets/ascend_capital_l5.jpg'
import missionImg from '../assets/team/los_ascend_bizcap.png'
import lokeshChandra from '../assets/team/Lokesh_Chandra.jpg'
import gauravMaheshwari from '../assets/team/gaurav.jpg'
import Ahter from '../assets/investors/ather.png'
import eclearLeading from '../assets/investors/eclear_leading.png'
import euler from '../assets/investors/euler.png'
import heroelectric from '../assets/investors/heroelectric.png'
import incred from '../assets/investors/incred.png'
import kkr from '../assets/investors/kkr.png'
import mahindra from '../assets/investors/mahindra.png'
import mass from '../assets/investors/mass.png'
import mayuri from '../assets/investors/mayuri.png'
import northern from '../assets/investors/northern.png'
import osm from '../assets/investors/osm.png'
import piaggio from '../assets/investors/piaggio.png'
import sarthi from '../assets/investors/sarthi.png'
import shriram from '../assets/investors/shriram.png'
import trintex from '../assets/investors/trintex.png'
import uc from '../assets/investors/uc.png'
import usha from '../assets/investors/usha.png'
import western from '../assets/investors/western.png'
import yc from '../assets/investors/yc.png'
import zypp from '../assets/investors/zypp.png'
import Akash from '../assets/team/akash.jpg'
import Amit from '../assets/team/amit.jpeg'
import Anuradha from '../assets/team/anuradha.jpg'
import Avi from '../assets/team/avi_mathur.jpeg'
import Deepak from '../assets/team/deepak_k.jpg'
import Devendra from '../assets/team/devendra.jpg'
import Ijaaz from '../assets/team/ijaaz.jpeg'
import Jyoti from '../assets/team/jyoti_prakash.jpeg'
import Madhu from '../assets/team/Madhu.jpg'
import Manish from '../assets/team/manish_agarwal.jpeg'
import Manoj from '../assets/team/manoj.jpeg'
import Mohit from '../assets/team/mohit_singh.jpg'
import Mukesh from '../assets/team/mukesh.jpg'
import Neel from '../assets/team/neelanchal.jpeg'
import Payal from '../assets/team/payals.jpg'
import Ravi from '../assets/team/ravi_kumar.jpeg'
import Seema from '../assets/team/seema.jpeg'
import Kharra from '../assets/team/Shailendra_Kharra.jpg'
import Shikha from '../assets/team/Shikha.jpg'
import Sneha from '../assets/team/sneha_singh.jpeg'
import Tanish from '../assets/team/tanish.jpg'
import Vijay from '../assets/team/vijay_tiwari.jpeg'
import Vishal from '../assets/team/vishal_choudhary.jpeg'
import Jitesh from '../assets/team/jitesh.jpeg'
import Harsh from '../assets/team/harsh.jpeg'
import Kailash from '../assets/team/kailash.jpeg'
import Laxmikant from '../assets/team/laxmi.jpeg'
import Shantnu from '../assets/team/shantnu.jpeg'
import Chand from '../assets/team/chand.jpeg'
import Manvi from '../assets/team/manvi.jpeg'
import Rohit from '../assets/team/rohit.jpeg'
import Ashok from '../assets/team/ashok.jpeg'
import Yadram from '../assets/team/yadram.jpeg'
import Ranjna from '../assets/team/ranjana.jpeg'
import Keshav from '../assets/team/keshav.jpeg'
import technologyMain from '../assets/analytics.png'
import los from '../assets/los_ascend_bizcap.png'
import lms from '../assets/lms_ascend_bizcap.png'
import collection from '../assets/collection_ascend_bizcap.png'
import staffApp from '../assets/staff_management.png'


// evcred>>>>>>>>>>>

import evcredLogo from '../assets/evcred_logo.png'
import homeBg from '../assets/homeBg.png'
import erick1a from '../assets/erickshaw/1/2.jpg'
import erick1b from '../assets/erickshaw/1/3.jpg'
import erick1c from '../assets/erickshaw/1/4.jpg'
 
export default {
  evcredLogo,
  homeBg,

  erick1a,
  erick1b,
  erick1c,

  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  ascendLogo,
  mainHeaderImg,
  footerBg,
  whyChooseUsBg,
  chooseUs,
  SectionBg2,
  playStore,
  storeRatting,
  masFinance,
  mortgageLoan,
  eRickShaw,
  eBike,
  l5Loan,
  missionImg,
  lokeshChandra,
  gauravMaheshwari,
  Ahter,
  eclearLeading,
  euler,
  heroelectric,
  incred,
  kkr,
  mahindra,
  mass,
  mayuri,
  northern,
  osm,
  piaggio,
  sarthi,
  shriram,
  trintex,
  uc,
  usha,
  western,
  yc,
  zypp,
  Akash,
  Amit,
  Anuradha,
  Avi,
  Deepak,
  Devendra,
  Ijaaz,
  Jyoti,
  Madhu,
  Manish,
  Manoj,
  Mohit,
  Mukesh,
  Neel,
  Payal,
  Ravi,
  Seema,
  Kharra,
  Shikha,
  Sneha,
  Tanish,
  Vijay,
  Vishal,
  Jitesh,
  Harsh,
  Kailash,
  Laxmikant,
  Shantnu,
  Chand,
  Manvi,
  Rohit,
  Ashok,
  Yadram,
  Ranjna,
  Keshav,
  technologyMain,
  los,
  lms,
  collection,
  staffApp
};
