import React, {useState} from 'react'
import { Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from './Pages/Home/Home';
import { Navbar } from './components';
import { Footer } from './container';
import Lending from './Pages/Lending/Lending';
import AboutUs from './Pages/AboutUs/AboutUs'
import ContactUs from './Pages/ContactUs/ContactUs';
import PrivacyPoliy from './Pages/PrivacyPolicies/PrivacyPolicy';
import Technology from './Pages/Technology/Technology';
import SingleVehicle from './Pages/SingleVehicle/SingleVehicle';
import VehicleLists from './Pages/VehicleLists/VehicleLists';

const Main = (props) => {
    
    const[menuBackground, setMenuBackground] = useState('')
    const handleNavBarBg = (e) => {
        window.scrollTo(0, 0)
        if(e === 'home'){
            setMenuBackground('')
            localStorage.setItem('nav_bar_common_bg', '')
        }else {
            setMenuBackground('nav_bar_common_bg')
            localStorage.setItem('nav_bar_common_bg', 'nav_bar_common_bg')
        }
    }
    
    return (
        <BrowserRouter>
        <div className='main'>
            <Navbar handleNavBarBg={(e) => handleNavBarBg(e)} bg={localStorage.getItem('nav_bar_common_bg')} />  
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/products' element={<Lending />} />
                <Route path='/about-us' element={<AboutUs />} /> 
                <Route path='/contact-us' element ={<ContactUs />} />
                <Route path='/privacy-policies' element ={<PrivacyPoliy />} />
                <Route path='/technology' element ={<Technology />} />
                <Route path='vehicle_details/:vehicle_id' element ={<SingleVehicle id=':vehicle_id' />} />
                <Route path='all_vehicles' element = {<VehicleLists />} />
            </Routes>
            <Footer handleNavBarBg={(e) => handleNavBarBg(e)} bg={menuBackground} />
            {/* <div className='app__footer-copyright'>
                <p className='footer_copyright-text'>@{new Date().getFullYear()} LCGM Technologies Pvt. Ltd. All rights reserved.</p>
            </div> */}
        </div>
        </BrowserRouter>
    )
}

export default Main;