import React, {useContext, useEffect, useState} from 'react';
import CarList from '../../components/CarList/CarList';
import {MdElectricRickshaw, MdElectricBike, MdMapsHomeWork} from 'react-icons/md'
import './CarListContainer.css'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { Link } from 'react-router-dom';
import HomeContextProvider from '../../context/homeContext';
import HomeContext from '../../context/homeContext'
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { API_URL } from '../../constants/apis';

const CarListContainer = () => {

    const [vehicles, setVehicles] = useState([])
    
    useEffect(() => {
        const asyncFn = async () => {
            const res = await axios.get(`${API_URL}/used_vehicle`);
            if(!isEmpty(get(res, 'data', []))){
                const vehicleData = get(res, 'data.data', [])
                setVehicles(vehicleData)
            }
        }
        asyncFn()
    }, [])

    return (
        <div className='featured_cars section_padding'>
            <HomeContextProvider>
                <SectionTitle title="Featured E-Rickshaw" />
                <div className='featured_car_list'>
                    {vehicles.map((vehicle, index) => {
                        if(index < 4){
                            return <CarList key={index} vehicleDetails = {vehicle} folder={index+1} />
                        }
                    })}
                    
                    {/* <CarList />
                    <CarList />
                    <CarList /> */}
                    {/* <CarList />
                    <CarList />
                    <CarList /> */}
                </div>
                    <div className='view_more_vehicle'><Link to="/all_vehicles">View More</Link></div>
            </HomeContextProvider>
        </div>
    );
}

export default CarListContainer;

