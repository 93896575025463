import React from 'react';
import './Button.css'

const Button = ({name, width, height, backgroundColor='#FFFFFF', fontSize, fontColor, alt = '', link, marginTop}) => (
  <div className='app__button'>
    <a href={`${link ? link : '/'}`}><button
      className='app__button_hover'
      type='button'
      style={{width : width ? `${width}px` : '100px', height : height ? `${height}px` : '50px', backgroundColor : backgroundColor, color : fontColor ? fontColor : '#27AAE2', borderRadius : 10, borderColor : 'none', border : 0, cursor:'pointer', fontSize : fontSize ? fontSize : 20, marginTop}}
      alt={alt}
    >
    {name}
    </button></a>
  </div>
);

export default Button;

