import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import './PrivacyPolicy.css'

const PrivacyPoliy = () => {
    return(
        <div className="privacy_policies">
            
        </div>      
    )
}
export default PrivacyPoliy;