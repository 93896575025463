import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import images from '../../constants/images';
import SectionCard from '../../components/SectionCard/SectionCard';
import {GiFlexibleStar} from 'react-icons/gi'
import {MdQuickreply} from 'react-icons/md'
import {TbChecklist} from 'react-icons/tb'
import {BsPersonBoundingBox} from 'react-icons/bs'
import { HiCurrencyRupee } from "react-icons/hi";
import {GiGears} from 'react-icons/gi'
import {GiAutoRepair} from 'react-icons/gi'
import {TiStarburst} from 'react-icons/ti'

import './WhyUs.css'

const WhyUs = () => {
    return (
        <div className='app__section-single1 section_padding'>
            <SectionTitle title='Why Choose Us ?' />
            <div className='app_section_whyus section_margin'>
                <SectionCard icon={<HiCurrencyRupee />} title = 'Easy Loan' iconHeight={80} iconWidth={80} checklist='Minimum Down Payment///Low Interest Rate' />
                <SectionCard icon={<TbChecklist />} title = 'Detailed Inspection' iconHeight={80} iconWidth={80} checklist='50+ Parameters///Best Value' />
                <SectionCard icon={<GiAutoRepair />} title = 'Warranty & Return' iconHeight={80} iconWidth={80} checklist='Up to 12 Months///7 Days Return Policy' />
                <SectionCard icon={<TiStarburst />} title = 'Only On EvCred' iconHeight={80} iconWidth={80} checklist='100+ Vehicle List///Fixed Price' />
            </div>
        </div>
    );
}

export default WhyUs;

