import React from 'react';
import './SectionTitle.css'

const SectionTitle = ({title, fontColor, fontSize}) => (
  <div className='app__section-heading'>
    <p className='p__section_heading-jost' style={{color : fontColor ? fontColor : '#1E73B1', fontSize : `${fontSize && fontSize}px`}}>{title}</p>
  </div>
);

export default SectionTitle;
