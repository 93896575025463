import React, { createContext, useEffect, useState } from 'react'
import axios from "axios";
// import { model, oem } from '../constant/data';

export const HomeContext = createContext()

function HomeContextProvider(props) {
    const [omiBrands, setOemBrands] = useState([]);
    const [models, setModels] = useState([])
    
    const getOmi = async () => {
        const res = await axios.get('http://localhost:8009/api/v0.1.1/used_vehicle');
        // setOemBrands(res?.data?.data);
        // setOemBrands(oem)
    }
    useEffect(()=>{
        getOmi();
    },[])
    
    const getModelsForTheBrand = async (oemId) => {
        // const res = await axios.get(`https://staging.ascendcap.in/api/vehicle/v0.1.1/oems/${oemId}/models`);
        // setModels(res?.data?.data)
        // setModels(model)
    }
    return (
        <HomeContext.Provider value={{
            omiBrands,
            models,
            getModelsForTheBrand
        }}>
            {props.children}
        </HomeContext.Provider>
    )
}

export default HomeContextProvider