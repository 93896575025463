import { Header, LoanProcess, WhyUs, LoanProducts, Investors, Testimonial, CarListContainer } from '../../container';
import { API_URL } from '../../constants/apis';

const Home = () => {
    return (
        <> 
            <Header />
            <WhyUs />
            <CarListContainer />
        </>
    );
}

export default Home;