import React from 'react';
import { SubHeading } from '../../components';
import images from '../../constants/images'
import './Header.css';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';

const Header = () => (
  <div className='app_header' id = 'apply_loan'>
    {/* <img className='home_bg' src={images.homeBg} alt="" /> */}
    <div className='app__header app__wrapper1 section_padding' id='home'>
      <div className='app__wrapper_info'>
        <h1 className='app_header_h1'>Now Own an EV at Half Price</h1>
        <SubHeading title='100% Quality Assurance | Finance on Used EV' />
        <div className='app_ratting'>
          {/* <Button name="Buy Now" width={150} height={50} link="/all_vehicles"/> */}
          <Link to ="/all_vehicles" ><p className='app_header_h2'>Buy Now</p></Link>
        </div>
      </div>
      <div className='app__wrapper_img'>
        <img className='header_image_main' src={images.mainHeaderImg} alt='evcred' />
      </div>
    </div>
  </div>
);

export default Header;
