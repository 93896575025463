import React,{useState} from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import './Section.css'
import Button from '../Button/Button';
import { map, isEmpty } from 'lodash';

const Section = ({title, description,img, imgPositionRight, bgImage, loanDetails=[], isButton = true}) => {
    
    if(!isEmpty(loanDetails)){
        loanDetails = loanDetails ? loanDetails.split('///') : []
    }
    
    const toggleAccordion = () => {
        let acc = document.getElementsByClassName("accordion");
        let i;
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                let panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }

    return (
        <div className='app__section-single'>
            <div style={{backgroundImage : `url(${bgImage})`, height :bgImage ? '620px' : 'auto', width : '100%'}} className='section_single'>
                <div className={`section_single_margin ${imgPositionRight ? '' : 'reverse'}`}>
                    <div className='section-single-left'>
                        <div className='section_title'>
                            <h2>{title}</h2>
                        </div>
                        <div className='section_description'>
                            <p className='p_section_description'>{description}</p>
                        </div>
                        <div className='accordion_section'>
                            <button className="accordion" onClick={() => toggleAccordion()}>View More</button>
                            <div className="panel">
                                <div className='loan_details'>
                                    <ul className='loan_detail_ul'>
                                        {!isEmpty(loanDetails) ? loanDetails.map((detail) => {
                                            return <li className='loan_detail_li'>{detail}</li>
                                        }): <></>}
                                    </ul> 
                                </div>
                            </div>
                        </div>
                        <div className="description_loan_product_single">
                                <div className='loan_details'>
                                    <ul className='loan_detail_ul'>
                                        {loanDetails.map((detail) => {
                                            return <li className='loan_detail_li'>{detail}</li>
                                        })}
                                    </ul> 
                                </div>
                            </div>
                    </div>
                    <div className='section-single-right'>
                        <div className='section_image'>
                            <img className='image_main' src={img} height='300px'/>
                        </div>
                    </div>
                </div>
                <div style={{marginTop : 10}}>
                    {isButton && <Button name='Apply Now' width={200} height={50} fontSize={24} link='https://play.google.com/store/apps/details?id=com.lcgm.ascend_loan' />}
                </div>
            </div>
        </div>
    );
}

export default Section;

