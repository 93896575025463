import React from "react";
import Section from "../../components/Section/Section";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import images from "../../constants/images";
import './Technology.css'

const Technology = () => {
    return (
        <div className="app_technology">
            <Section bgImage={images.SectionBg2} isButton={false} title="Complete technology suite for any lending firm" description="Futuristic lending solution developed on advanced technology platform, innovating and disrupting retail lending market. Cloud deployment provides flexibility in scale while optimizing costs by supporting pay-per-use models."  img={images.technologyMain}/>
            <SectionTitle  title="Our Products"/>
            <Section isButton={false} title="Loan Origination System (LOS)" imgPositionRight={true} description="Accelerated go-to-market and onboard customer within minutes across multiple platforms (web/mobile app)" loanDetails="Advanced CRM(Customer Relationship Management)///Fully Automated KYC///Automated documents reading to save operations cost///Easy loan pipeline and status tracking for staff and channel partners///Credit rule engine and integration with credit bureaus///Configurable workflows and loan products///Deviation and checklist creations and role based approvals" img={images.los}/>
            <Section isButton={false} title="Loan Management System (LMS)" description= 'LMS is an advanced and comprehensive bank loan management system that aims to improve the quality, turnaround time and service for end-customers. It enables banks to improve the agility, transparency and efficiency of their lending solutions. As a loan management solution, it enables financial institutions to automate the processes for achieving cost savings and enhanced customer experience.' loanDetails="Configurable MIS and Reports///Analytics///Easy accounting integration///Flexiblility for loan restructuring, balance transfer and moratorium///Flexible payment handling, maintain easy to read ledgers///Effective and timely customers communication and notifications" img={images.lms}/>
            <Section isButton={false} title="Collection" imgPositionRight={true} description="Complete collection suite for end-to-end collection process starting from emi collection to legal process. Configurable collection process and strategy to reduce operations cost. Integrate with multiple collection agencies and receive payments in various formats (cash/online payment)" loanDetails="Automatic collection strategy, loan assignments and route planning///Rule based Payment Reminders and Notifications///NACH/eNACH registration and debit emi support///Flexible workflows///Configurable dashboard to check portfolio performance and check early indicators///Payment links, electronic receipts///Maintain epossessed asset details, with support for valuation and disposal" img={images.collection}/>
            <Section isButton={false} title="Staff Productivity" description="Improve your staff productivity and reduce costs Improve your staff productivity and reduce costs" loanDetails="Attendance and HR Portal///Automatic task allocations and updates tracking///Internal chat///Dashboard for team performance and TAT///Geolocations and geotagging///Pendencies tracking" img={images.staffApp}/>
        </div>
    );
}

export default Technology;