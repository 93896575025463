import React, {useState, useEffect} from 'react';
import {GiHamburgerMenu} from 'react-icons/gi'
import {MdClose} from 'react-icons/md'
import {FaDesktop,FaRegChartBar,FaEye, FaCubes} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import images from '../../constants/images'
import './Navbar.css';
import { isEmpty } from 'lodash';

const Navbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleSubMenu, setToggleSubMenu] = useState(false)
  // const [submenuPositionX, setSubMenuPositionX] = useState(undefined)
  const [submenuPositionY, setSubMenuPositionY] = useState(undefined)
  const [sticky, setSticky] = useState(undefined)
  const [menuBackground, setMenuBackground] = useState('')

  useEffect(() => {
    navbarBg()
    // props.handleNavBarBg('revert')
    const url = 'http://localhost:3001/'
    const windowLocation = window.location.href
    if(url !== windowLocation){
      setMenuBackground('nav_bar_common_bg')
    }else {
      setMenuBackground('')
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if(position >= 100){
      setSticky('sticky')
    }else {
      setSticky('')
    }
  };

  const setSubMenuPosition = (e, id) => {
    const elem = document.getElementById(id);
    let rect = elem.getBoundingClientRect();
    // setSubMenuPositionX(e.screenX)
    setSubMenuPositionY((rect.left).toFixed(0))

  }

  const navbarBg = () => {
    const elements = document.getElementById('apply_loan')
    if(!isEmpty(elements)){
      setMenuBackground('nav_bar_common_bg')
    }else {
      setMenuBackground('')
    }
  }

  return(
    <nav className={`app__navbar ${sticky} ${props.bg}`}>
      <div className='app__navbar-logo'>
        <Link onClick={() => props.handleNavBarBg('home')} style={{display:'flex', justifyContent: 'center',alignItems: 'center'}}  to = '/' ><img src={images.evcredLogo} alt="evcred logo" />
        </Link>
      </div>
      <ul className='app__navbar-links'>
        <li className='p_menu_jost'><Link to ='/contact-us' onClick={() => props.handleNavBarBg('product')}>Contact</Link></li>
        {/* <li id="product" className='p_menu_jost'><a style={{width:'500px',height:'500px'}} onMouseOver={() => {setToggleSubMenu(true)}} onMouseMove={(e,menu) => setSubMenuPosition(e, 'product')} >Technology</a></li> */}
        
        {/* -------------------sub Menu>>>>>>>>>>>>>>>>>>> */}
        
        {/* {toggleSubMenu && <div style={{left:`${submenuPositionY}px`}} className='app__bar-submenu' onMouseLeave={() => {setToggleSubMenu(false)}}>
          <ul className='app__navbar-submenu-links'>
            <div className='sub-menu-list'><FaDesktop color='#C23EA6' fontSize={27} /><li className='p_menu_jost'><a href='#'>Loan Organisation System</a></li></div>
            <div className='sub-menu-list'><FaRegChartBar color='#C23EA6' fontSize={27} /><li className='p_menu_jost'><a href='#'>Loan Management System</a></li></div>
            <div className='sub-menu-list'><FaCubes color='#C23EA6' fontSize={27} /><li className='p_menu_jost'><a href='#'>Field Collection</a></li></div>
            <div className='sub-menu-list'><FaEye color='#C23EA6' fontSize={27} /><li className='p_menu_jost'><a href='#'>Field Staff Tracking</a></li></div>
          </ul>
        </div>} */}
        {/* -------------------sub Menu>>>>>>>end>>>>>>>>>>>> */}

        {/* <li className='p_menu_jost'><a href='#'>Contact</a></li> */}
        {/* <li className='p_menu_jost'><Link to='/' onClick={() => props.handleNavBarBg('about')}>About Us</Link></li> */}
      </ul>
      <div className='app__navbar-button'>
        {/* <a href='https://forms.gle/W2uEGMQm7K13cSFe8' target='_blank'><button type='button'>Sign In/Sign Up</button></a> */}
      </div>
      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu color="#5A960C" fontSize={27} onClick={() => {setToggleMenu(true)}} />
        {toggleMenu && <div className='app__navbar-smallscreen_overlay flex_center slide-bottom'>
          <div className='app_mobile_menu'>
            <div className='menu_mobile_logo'>
              <a href='/' className='menu_mobile_logo_link'><img className='mobile_menu' src={images.evcredLogo}></img></a>
            </div>
            <div className='mobile_menu_close'>
              <MdClose fontSize={27} className="overlay_close" onClick={() => {setToggleMenu(false)}} />
            </div>
          </div>
          <ul className='app__navbar-smallscreen-links'>
            <li className='p_menu_jost'><Link to ='/contact-us' onClick={() => {setToggleMenu(false);props.handleNavBarBg('products')}}>Contact</Link></li>
            {/* <li className='p_menu_jost'><Link onClick={() => {setToggleMenu(false);props.handleNavBarBg('products')}} to='/about-us'>About Us</Link></li> */}
            {/* <li className='p_menu_jost'><a href='/'>Sign In/Sign Up</a></li> */}
            {/* <li className='p_menu_jost'><a href='https://play.google.com/store/apps/details?id=com.lcgm.ascend_loan' target="_blank">Apply For Loan</a></li> */}
          </ul>
        </div>}
      </div>
    </nav>
  )
};

export default Navbar;
