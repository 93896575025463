import React,{useState} from 'react';
import './CarList.css'
import {BsSpeedometer, BsBatteryCharging} from 'react-icons/bs';
import {BiRupee} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

const Section = ({vehicleDetails, folder}) => {
    return (
        <div className='app__car-single'>
            <Link className='vehicle_card' style={{width:'100%'}} to = {`/vehicle_details/${vehicleDetails.used_vehicle_id}`} state = {{ id : vehicleDetails.used_vehicle_id }} >
                <div className='vehicle_card_coontent'>
                    <div className='car_list_img'>
                        <img className='car_img' src={`/used_images/${folder}/${vehicleDetails.main_image}`} />
                    </div>
                    <div className='car_list_details'>
                        <div className='car_list_model'>
                            <div className='car_model'><p>{vehicleDetails.brand} {vehicleDetails.model}</p></div>
                            <div className='car_make_year'><p>Year - {moment(vehicleDetails.invoice_date).format('YYYY')}</p></div>
                        </div>
                        <div className='car_list_model'>
                            <div className='car_km'><BsSpeedometer className='car_list_icon' /><p>{vehicleDetails.odometer_reading} Km</p></div>
                            <div className='car_battery'><BsBatteryCharging className='car_list_icon' /><p>{vehicleDetails.battery_chemistry}</p></div>
                        </div>
                        <div className='car_list_model'>
                            <div className='car_resale_price font_bold'><BiRupee className='car_list_icon' /><p>{vehicleDetails.estimated_price}/-</p></div>
                            <div className='car_emi'>EMI 6000/month*</div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Section;

