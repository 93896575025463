import React from 'react';
import Section from '../../components/Section/Section';
import images from '../../constants/images';
// import SectionTitle from '../../components/SectionTitle/SectionTitle';

import './Sections.css';

const Sections = () => {

    const contnetAccordian = () => {
        const accordianList = document.getElementsByClassName("accordion");
        let i;
        for (i = 0; i < accordianList.length; i++){
            accordianList[i].addEventListener("click", function() {
                this.classList.toggle("active");
                const panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                  panel.style.maxHeight = null;
                } else {
                  panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }

    return (
        <div className='app_sections'>
        </div>
    );
}

export default Sections;
