import { isEmpty } from 'lodash';
import React from 'react';
import './SectionCard.css'

const SectionCard = ({icon, title, details, iconHeight, iconWidth, isPerson = true, checklist}) => {
  if(!isEmpty(checklist)){
    checklist = checklist.split('///')
  }
  return (
    <div className='app_single_card_section'>
      <div className='app__section-card'>
        <div className='section_card-icon' style={{width:`${iconHeight}px`,height : `${iconWidth}px`, clipPath : isPerson ? 'circle(50%)' : '', fontSize : 50}}>
            {icon}
        </div>
        <div className='section_card-title'>
            <p className='p_section_card-title'>{title}</p>
        </div>
        <div className='section_card-details'>
            <p className='p_section_card-details'>{details}</p>
        </div>
        <div className='choous_us_section'>
          <ul className='choose_us_checklist'>
          {checklist && checklist.map((list) => {
            return <li>{list}</li>
          })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SectionCard;

